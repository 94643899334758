import React from "react";
import Img from "gatsby-image";
import { graphql } from "gatsby";
import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";

import SEO from "../components/seo";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";

export const query = graphql`
  query {
    file(name: { eq: "office" }) {
      name
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Contact = ({ data }) => {
  const intl = useIntl();

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "contact" })} />
      <Title text={intl.formatMessage({ id: "contact" })} />
      <a
        css={css`
          font-size: 18px;
          line-height: 26px;
          @media screen and (min-width: 768px) {
            font-size: 20px;
            line-height: 28px;
          }
        `}
        href="mailto:mail@hanstaennler.ch"
      >
        mail@hanstaennler.ch
      </a>
      <Img
        fluid={data.file.childImageSharp.fluid}
        css={css`
          margin-top: 2rem;
        `}
      />
      <Return />
    </Layout>
  );
};

export default Contact;
